<template>
  <div>
    <div class="row">
      <div class="col mb-3">
       <div class="form-inline justify-content-end">
         <b-form-select
             v-model="analyticsQuery"
             class="analytics-filter"
             size="md"
             :options="['Last 7 Days', 'Last 30 Days', 'Last 90 Days' ,'Lifetime']"
             @change="getAnalytics"
         ></b-form-select>
       </div>
      </div>
    </div>
    <div class="row">
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Search & Opt-ins</th>
                <th class="text-orange font-size-20 font-weight-bold text-right"></th>
              </tr>
              <tr>
                <td class="pl-4">Prospects</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.search_prospects}}</td>
              </tr>
              <tr>
                <td class="pl-4">Leads</td>
                <td class="text-right text-success font-weight-bold">{{analytics.search_leads}}</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Total Traffic</th>
                <th class="text-orange font-size-20 font-weight-bold text-right">{{analytics.total_traffic}}</th>
              </tr>
              <tr>
                <td class="pl-4">Desktop</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.total_traffic_desktop}}</td>
              </tr>
              <tr>
                <td class="pl-4">Mobile</td>
                <td class="text-right text-success font-weight-bold">{{analytics.total_leads_mobile}}</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Total Leads</th>
                <th class="text-orange font-size-20 font-weight-bold text-right">{{analytics.total_leads}}</th>
              </tr>
              <tr>
                <td class="pl-4">Desktop</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.total_leads_desktop}}</td>
              </tr>
              <tr>
                <td class="pl-4">Mobile</td>
                <td class="text-right text-success font-weight-bold">{{analytics.total_leads_mobile}}</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-3 col-lg-6 col-md-6">
        <div class="card">
          <div class="card-body p-2">
            <table v-if="!loadingAnalytics" class="table table-borderless font-size-17">
              <tr>
                <th>Conversion Rate</th>
                <th class="text-orange font-size-20 font-weight-bold text-right">{{analytics.conversion_rate}}%</th>
              </tr>
              <tr>
                <td class="pl-4">Desktop</td>
                <td class="text-right text-dark-blue font-weight-bold">{{analytics.conversion_rate_desktop}}%</td>
              </tr>
              <tr>
                <td class="pl-4">Mobile</td>
                <td class="text-right text-success font-weight-bold">{{analytics.conversion_rate_mobile}}%</td>
              </tr>
            </table>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <template v-if="!loadingBusinesses">
              <h5>Recent Prospects & Leads</h5>
              <div class="table-responsive mb-0 table-striped table-bordered" style="border-radius: 8px">
                <b-table
                  :items="businesses"
                  :fields="datatable.columns"
                  :sort-by.sync="datatable.queries.sort_by"
                  :sort-desc.sync="datatable.queries.sort_desc"
                  :per-page="datatable.queries.per_page"
                  :no-local-sorting="true"
                >
                  <div class="text-center text-danger my-2">
                    <b-spinner class="align-middle"></b-spinner>
                  </div>
                  <template v-slot:cell(lead_status)="data">
                    <strong class="text-primary" :class="{ 'text-success': data.item.lead_status === 'Lead' }">{{data.item.lead_status}}</strong>
                  </template>
                  <template v-slot:cell(actions)="data">
                    <a :href="data.item.google_maps_url" class="mr-1" target="_blank"><img style="max-height: 30px" src="https://review-link.sfo3.cdn.digitaloceanspaces.com/Icon.png"></a>
                    <router-link
                        :to="{
                        name: 'agency.businesses.edit',
                        params: { id: data.item.id },
                      }"
                        class="btn btn-success btn-sm ml-1"
                    >
                      View
                    </router-link>
                  </template>
                </b-table>
              </div>
              <div class="row mt-4">
                <div class="col text-right">
                  <router-link
                      :to="{
                        name: 'agency.businesses.index',
                      }"
                      class="btn btn-dark-blue btn-sm"
                  >
                    See All
                  </router-link>
                </div>
              </div>
            </template>
            <div v-else class="text-center">
              <b-spinner></b-spinner>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      analyticsQuery: 'Last 7 Days',
      analytics:{
        conversion_rate: '',
        conversion_rate_desktop: '',
        conversion_rate_mobile: '',
        search_leads: '',
        search_prospects: '',
        total_leads: '',
        total_leads_desktop: '',
        total_leads_mobile: '',
        total_traffic: '',
        total_traffic_desktop: '',
        total_traffic_mobile: '',
      },
      datatable: {
        items: [],
        totalItems: 1,
        columns: [
          { key: 'small_date', label: 'Date' },
          { key: 'business_name', label: 'Business' },
          { key: 'full_name', label:'Name' },
          { key: 'email', label: 'Email' },
          { key: 'phone_number', label: 'Phone' },
          { key: 'lead_status', label: 'Status' },
          { key: 'actions', label: 'G Link' },
        ],
        queries: {
          per_page: 10,
          page: 1,
          status: 'active',
          search: null,
          sort_by: 'id',
          sort_desc: true,
        },
      },
      loadingBusinesses: false,
      loadingImpersonate: false,
      loadingAnalytics:false
    }
  },

  computed: {
    user() {
      return this.$store.getters['auth/user']
    },

    businesses() {
      return this.$store.getters['business/all'] || []
    },

    totalBusinesses() {
      return this.$store.getters['business/total']
    },
  },

  mounted() {
    if (this.$store.getters['business/all'] === null) {
      this.getBusinesses()
    } else {
      this.datatable.queries = this.$store.getters['business/queries']
    }
    this.getAnalytics();
  },

  methods: {
    onPageChanged(page) {
      this.datatable.queries.page = page
      this.getBusinesses()
    },

    getBusinesses() {
      this.loadingBusinesses = true

      this.$store
        .dispatch('business/getAll', {
          queries: this.datatable.queries,
          page: 'index',
        })
        .then((res) => {
          this.loadingBusinesses = false
        })
        .catch(() => {
          this.loadingBusinesses = false
        })
    },

    getAnalytics() {
      this.loadingAnalytics = true
      this.$store
          .dispatch('agency/getAnalytics', {
            query: this.analyticsQuery,
            agencyId: this.user.agency.id
          })
          .then((res) => {
            if(res.hasOwnProperty('search_prospects')) {
              this.analytics = res
            }
            this.loadingAnalytics = false
          })
          .catch(() => {
            this.loadingAnalytics = false
          })
    },

    impersonate(userId) {
      this.loadingImpersonate = true

      this.$store
        .dispatch('auth/impersonate', userId)
        .then((res) => {
          this.$store
            .dispatch('auth/getProfile')
            .then((user) => {
              this.$router.push('/' + user.role.toLocaleLowerCase())
              this.loadingImpersonate = false
            })
            .catch(() => {
              this.loadingImpersonate = false
            })
        })
        .catch(() => {
          this.loadingImpersonate = false
        })
    },
  },
}
</script>
